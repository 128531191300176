import "leaflet/dist/leaflet.css";
import React, { useEffect, useState, useRef } from "react";
import L from "leaflet";
import { LayersControl } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, useMap, Pane, Marker } from "react-leaflet";
import "./MapDisplay.css";
import { ClipLoader } from "react-spinners";
import {
  EllipsisRasterLayer,
  EllipsisVectorLayer,
} from "react-leaflet-ellipsis";
import { BiX } from "react-icons/bi";
import { getClusteringCoord } from "../../utils/apicalls";
import Legend from "./Legend";

const customIcon = new L.Icon({
  iconUrl: require("../../Icon.svg").default,
  iconSize: new L.Point(40, 47),
});

function MapDisplay({
  lat,
  lon,
  loading,
  setLoading,
  selectedDatasetDetails,
  enableLayerChangeOpt,
  selectedModelName,
  setShowPopUp,
  showPopUp,
  setShowDirection,
  isAnalyticsLayer,
  setIsAnalyticsLayer,
  isSateliteLayer,
  setIsSateliteLayer,
  selectedTimeStamp,
  clusteringData,
  clusteringDataAlert,
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [shouldFlyTo, setShouldFlyTo] = useState(false);
  const [clusteringCoord, setClusteringCoord] = useState([]);
  const timeoutRef = useRef(null);

  function FlytoPosition({ val }) {
    const map = useMap();
    map.flyTo(val, 12);
    setShouldFlyTo(false);
  }

  useEffect(() => {
    if (selectedModelName == "fire_detection") {
      if (selectedTimeStamp == "alert" || selectedTimeStamp == "2024-08-15") {
        if (clusteringDataAlert) {
          const y = getClusteringCoord(clusteringDataAlert, selectedTimeStamp);
          setClusteringCoord(y);
        }
      } else {
        if (clusteringData.length > 0) {
          const x = getClusteringCoord(clusteringData, selectedTimeStamp);
          setClusteringCoord(x);
        }
      }
    }
  }, [
    selectedModelName,
    selectedTimeStamp,
    clusteringData,
    clusteringDataAlert,
  ]);

  useEffect(() => {
    setShouldFlyTo(true);
    setIsAnalyticsLayer(true);
    setIsSateliteLayer(false);
  }, [lat, lon]);

  useEffect(() => {
    if (!enableLayerChangeOpt) {
      setIsAnalyticsLayer(true);
      setIsSateliteLayer(false);
    }
  }, [enableLayerChangeOpt, selectedModelName]);

  useEffect(() => {
    if (isProcessing) {
      timeoutRef.current = setTimeout(() => {
        setIsProcessing(false);
        setShowPopUp(false);
        setShowDirection(true);
      }, 5000);
    } else if (!isProcessing) {
      clearTimeout(timeoutRef.current);
    }
    return () => clearTimeout(timeoutRef.current);
  }, [isProcessing]);

  useEffect(() => {
    if (showPopUp) {
      setTimeout(() => {
        setIsProcessing(true);
      }, 3000);
    }
  }, [showPopUp]);

  const ZoomControl = () => {
    const map = useMap();
    const zoomControlRef = useRef(null);

    useEffect(() => {
      if (zoomControlRef.current) {
        map.removeControl(zoomControlRef.current);
      }

      const zoomControl = L.control.zoom({ position: "bottomright" });
      zoomControl.addTo(map);
      zoomControlRef.current = zoomControl;

      return () => {
        if (zoomControlRef.current) {
          map.removeControl(zoomControlRef.current);
        }
      };
    }, [map]);

    return null;
  };

  return (
    <div className="map-container">
      {loading ? (
        <div className="loader">
          <ClipLoader color={"#ffffff"} loading={loading} size={150} />
        </div>
      ) : (
        <MapContainer
          center={[lat, lon]}
          zoom={13}
          scrollWheelZoom={true}
          className="map-display"
          zoomControl={false}
          maxZoom={18}
          minZoom={8}
        >
          {shouldFlyTo && <FlytoPosition val={[lat, lon]} />}
          <Legend selectedModelName={selectedModelName} />

          <TileLayer
            noWrap={false} // Allow the map to wrap horizontally
            attribution='<a href="https://www.skyserve.ai/">SkyServe</a>'
            url="https://a.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png"
          />

          <ZoomControl />

          {showPopUp && !isProcessing && (
            <div className="pt-popup-box-msg">
              <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div>
                <div className="msg-processing">
                  <h3>Processing</h3>
                </div>
                <p>
                  Please hold on! We're processing your Task. This may take a
                  moment.
                </p>
              </div>
            </div>
          )}

          {isProcessing && (
            <div className="pt-popup-box-msg">
              <img src="/Images/Vector.png" alt="unable to load "></img>
              <div>
                <div className="msg-processing">
                  <h3 style={{ fontWeight: "400" }}>Scheduled</h3>
                  <BiX
                    onClick={() => {
                      setShowPopUp(false);
                      setIsProcessing(false);
                      setShowDirection(true);
                    }}
                    style={{ fontSize: "26px", cursor: "pointer" }}
                  />
                </div>
                <p>
                  Your task has been scheduled successfully. Please find the
                  status in the navbar
                </p>
              </div>
            </div>
          )}

          {selectedModelName === "fire_detection" ? (
            <>
              {isAnalyticsLayer && (
                <>
                  <Pane name="h3" style={{ zIndex: 20000 }}>
                    <MarkerClusterGroup chunkedLoading clusterPane="h3">
                      {clusteringCoord.map((address, index) => (
                        <Marker
                          key={index}
                          position={[address[1], address[0]]}
                          title={address[0]}
                          icon={customIcon}
                        ></Marker>
                      ))}
                    </MarkerClusterGroup>
                  </Pane>
                  <Pane name="h5" style={{ zIndex: 15000 }}>
                    <EllipsisVectorLayer
                      styleId={""}
                      pathId={selectedDatasetDetails.layer_id}
                      timestampId={selectedDatasetDetails.timestamp_id}
                      token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiMzgzNDBhYTEtNTJjMS00Zjg3LThjYTItYzgyYjI5NDU2YmM5IiwiaWF0IjoxNjg2NTc1NTI1LCJleHAiOjE2ODY2NjE5MjV9.lYKKpCken3WGoOcfIW7lkX81V5PRf_x6JgbHfQRSGI0"
                    />
                  </Pane>
                </>
              )}
              {isSateliteLayer &&
                selectedDatasetDetails &&
                selectedDatasetDetails.layer_id_input !== "none" &&
                selectedDatasetDetails.timestamp_id_input !== "none" && (
                  <Pane name="h4" style={{ zIndex: 10000 }}>
                    <EllipsisRasterLayer
                      styleId={selectedDatasetDetails.style_id}
                      pathId={selectedDatasetDetails.layer_id_input}
                      timestampId={selectedDatasetDetails.timestamp_id_input}
                      token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiMzgzNDBhYTEtNTJjMS00Zjg3LThjYTItYzgyYjI5NDU2YmM5IiwiaWF0IjoxNjg2NTc1NTI1LCJleHAiOjE2ODY2NjE5MjV9.lYKKpCken3WGoOcfIW7lkX81V5PRf_x6JgbHfQRSGI0"
                    />
                  </Pane>
                )}
            </>
          ) : selectedModelName == "oil_spills" ? (
            <>
              {isAnalyticsLayer && (
                <>
                  {selectedDatasetDetails.layer_id &&
                    selectedDatasetDetails.timestamp_id && (
                      <Pane name="h3" style={{ zIndex: 20000 }}>
                        <EllipsisVectorLayer
                          pane="h3"
                          pathId={selectedDatasetDetails.layer_id}
                          timestampId={selectedDatasetDetails.timestamp_id}
                        />
                      </Pane>
                    )}
                  {selectedDatasetDetails.layer_id_biodiversity &&
                    selectedDatasetDetails.timestamp_id_biodiversity && (
                      <Pane name="h4" style={{ zIndex: 30000 }}>
                        <EllipsisVectorLayer
                          pane="h4"
                          pathId={selectedDatasetDetails.layer_id_biodiversity}
                          timestampId={
                            selectedDatasetDetails.timestamp_id_biodiversity
                          }
                        />
                      </Pane>
                    )}
                  {selectedDatasetDetails.layer_id_effected_ship_line &&
                    selectedDatasetDetails.timestamp_id_effected_ship_line && (
                      <Pane name="h9" style={{ zIndex: 40000 }}>
                        <EllipsisVectorLayer
                          pane="h9"
                          pathId={
                            selectedDatasetDetails.layer_id_effected_ship_line
                          }
                          timestampId={
                            selectedDatasetDetails.timestamp_id_effected_ship_line
                          }
                        />
                      </Pane>
                    )}
                  {selectedDatasetDetails.layer_id_ship_line &&
                    selectedDatasetDetails.timestamp_id_ship_line && (
                      <Pane name="h6" style={{ zIndex: 50000 }}>
                        <EllipsisVectorLayer
                          pane="h6"
                          pathId={selectedDatasetDetails.layer_id_ship_line}
                          timestampId={
                            selectedDatasetDetails.timestamp_id_ship_line
                          }
                        />
                      </Pane>
                    )}
                </>
              )}
              {isSateliteLayer && (
                <Pane name="h7" style={{ zIndex: 10000 }}>
                  <EllipsisRasterLayer
                    styleId=""
                    pathId={selectedDatasetDetails.layer_id_input}
                    timestampId={selectedDatasetDetails.timestamp_id_input}
                    token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiMzgzNDBhYTEtNTJjMS00Zjg3LThjYTItYzgyYjI5NDU2YmM5IiwiaWF0IjoxNjg2NTc1NTI1LCJleHAiOjE2ODY2NjE5MjV9.lYKKpCken3WGoOcfIW7lkX81V5PRf_x6JgbHfQRSGI0"
                  />
                </Pane>
              )}
            </>
          ) : selectedModelName == "ship_detection" ? (
            <>
              {isSateliteLayer &&
                selectedDatasetDetails &&
                selectedDatasetDetails.layer_id_input !== "none" &&
                selectedDatasetDetails.timestamp_id_input !== "none" && (
                  <Pane name="h2" style={{ zIndex: 10000 }}>
                    <EllipsisRasterLayer
                      styleId={selectedDatasetDetails.style_id}
                      pathId={selectedDatasetDetails.layer_id_input}
                      timestampId={selectedDatasetDetails.timestamp_id_input}
                      token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiMzgzNDBhYTEtNTJjMS00Zjg3LThjYTItYzgyYjI5NDU2YmM5IiwiaWF0IjoxNjg2NTc1NTI1LCJleHAiOjE2ODY2NjE5MjV9.lYKKpCken3WGoOcfIW7lkX81V5PRf_x6JgbHfQRSGI0"
                    />
                  </Pane>
                )}

              {isAnalyticsLayer && selectedDatasetDetails && (
                <>
                  {selectedDatasetDetails.layer_id &&
                    selectedDatasetDetails.timestamp_id && (
                      <Pane name="h1" style={{ zIndex: 20000 }}>
                        <EllipsisRasterLayer
                          styleId={selectedDatasetDetails.style_id}
                          pathId={selectedDatasetDetails.layer_id}
                          timestampId={selectedDatasetDetails.timestamp_id}
                          token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiMzgzNDBhYTEtNTJjMS00Zjg3LThjYTItYzgyYjI5NDU2YmM5IiwiaWF0IjoxNjg2NTc1NTI1LCJleHAiOjE2ODY2NjE5MjV9.lYKKpCken3WGoOcfIW7lkX81V5PRf_x6JgbHfQRSGI0"
                        />
                      </Pane>
                    )}
                  {selectedDatasetDetails.layer_id_ais &&
                    selectedDatasetDetails.timestamp_id_ais && (
                      <Pane name="h4" style={{ zIndex: 30000 }}>
                        <EllipsisVectorLayer
                          pane="h4"
                          pathId={selectedDatasetDetails.layer_id_ais}
                          timestampId={selectedDatasetDetails.timestamp_id_ais}
                          token="your_token_here"
                        />
                      </Pane>
                    )}

                  {selectedDatasetDetails.layer_id_detection &&
                    selectedDatasetDetails.timestamp_id_detection && (
                      <Pane name="h8" style={{ zIndex: 40000 }}>
                        <EllipsisVectorLayer
                          pane="h8"
                          pathId={selectedDatasetDetails.layer_id_detection}
                          timestampId={
                            selectedDatasetDetails.timestamp_id_detection
                          }
                          token="your_token_here"
                        />
                      </Pane>
                    )}
                </>
              )}
            </>
          ) : (
            <>
              {isSateliteLayer &&
                selectedDatasetDetails &&
                selectedDatasetDetails.layer_id_input !== "none" &&
                selectedDatasetDetails.timestamp_id_input !== "none" && (
                  <Pane name="h2" style={{ zIndex: 10000 }}>
                    <EllipsisRasterLayer
                      styleId={selectedDatasetDetails.style_id}
                      pathId={selectedDatasetDetails.layer_id_input}
                      timestampId={selectedDatasetDetails.timestamp_id_input}
                      token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiMzgzNDBhYTEtNTJjMS00Zjg3LThjYTItYzgyYjI5NDU2YmM5IiwiaWF0IjoxNjg2NTc1NTI1LCJleHAiOjE2ODY2NjE5MjV9.lYKKpCken3WGoOcfIW7lkX81V5PRf_x6JgbHfQRSGI0"
                    />
                  </Pane>
                )}

              {isAnalyticsLayer &&
                selectedDatasetDetails &&
                selectedDatasetDetails.layer_id !== "none" &&
                selectedDatasetDetails.timestamp_id !== "none" && (
                  <>
                    <Pane name="h1" style={{ zIndex: 20000 }}>
                      <EllipsisRasterLayer
                        styleId={selectedDatasetDetails.style_id}
                        pathId={selectedDatasetDetails.layer_id}
                        timestampId={selectedDatasetDetails.timestamp_id}
                        token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiMzgzNDBhYTEtNTJjMS00Zjg3LThjYTItYzgyYjI5NDU2YmM5IiwiaWF0IjoxNjg2NTc1NTI1LCJleHAiOjE2ODY2NjE5MjV9.lYKKpCken3WGoOcfIW7lkX81V5PRf_x6JgbHfQRSGI0"
                      />
                    </Pane>
                    {selectedDatasetDetails.timestamp_id_lost &&
                      selectedDatasetDetails.layer_id_lost && (
                        <Pane name="h3" style={{ zIndex: 30000 }}>
                          <EllipsisVectorLayer
                            pathId={selectedDatasetDetails.layer_id_lost}
                            timestampId={
                              selectedDatasetDetails.timestamp_id_lost
                            }
                            token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiMzgzNDBhYTEtNTJjMS00Zjg3LThjYTItYzgyYjI5NDU2YmM5IiwiaWF0IjoxNjg2NTc1NTI1LCJleHAiOjE2ODY2NjE5MjV9.lYKKpCken3WGoOcfIW7lkX81V5PRf_x6JgbHfQRSGI0"
                          />
                        </Pane>
                      )}
                  </>
                )}
            </>
          )}
        </MapContainer>
      )}
    </div>
  );
}

export default MapDisplay;
