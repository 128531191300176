import React, { useState, useEffect } from "react";
import "./RadioButtonList.css";
import { BsFillPlayCircleFill, BsFillPauseCircleFill } from "react-icons/bs";
import { BsFillPauseFill, BsFillPlayFill } from "react-icons/bs";
import alertSound from "../../alert.wav";

function RadioButtonList({
  timestamps,
  onSelectTimestamp,
  setShowPopUp,
  showPopUp,
  upcomingPassDetails,
  selectedModelName,
  city,
  showAlert,
  setShowAlert,
}) {
  const [isActive, setIsActive] = useState(null);
  const [playBtnClicked, setPlayBtnClicked] = useState(false);
  const [activeI, setActiveI] = useState(timestamps.length - 1);
  // const [showAlert, setShowAlert] = useState(false);
  const [showAlertButton, setShowAlertButton] = useState(true);

  useEffect(() => {
    const x = upcomingPassDetails.find((e) => {
      return e.city === city && e.model_name === selectedModelName ? e : null;
    });
    if (x) {
      if (x.timer.insights === 0) {
        setShowAlert(true);
        setShowAlertButton(false);
      }
    } else {
      setShowAlert(false);
    }
  }, [city, selectedModelName, upcomingPassDetails]);

  const handleStartButtonClick = () => {
    setPlayBtnClicked(!playBtnClicked);
  };

  useEffect(() => {
    if (showAlert) {
      onSelectTimestamp("alert");
      setActiveI(timestamps.length);
      setIsActive(timestamps.length);
    } else {
      setActiveI(timestamps.length - 1);
      setIsActive(timestamps.length - 1);
    }
  }, [timestamps, showAlert]);

  useEffect(() => {
    let intervalId;
    var i = activeI,
      n = timestamps.length;
    function incrementIndex() {
      setIsActive(i);
      setActiveI(i);
      if (i < n) onSelectTimestamp(timestamps[i]);
      else {
        onSelectTimestamp("alert");
      }
      i++;
      if (!showAlert && i === n) i = 0;
      else if (showAlert && i === n + 1) i = 0;

      if (playBtnClicked) {
        intervalId = setTimeout(incrementIndex, 10000);
      }
    }

    if (playBtnClicked) {
      incrementIndex();
    } else {
      setIsActive(i);
      setActiveI(i);
    }

    return () => clearTimeout(intervalId);
  }, [playBtnClicked]);

  return (
    <div className="radio-button-list">
      <div className="timeline-container">
        {timestamps.length > 0 && (
          <>
            {/* <div
              onClick={handleStartButtonClick}
              style={{
                color: "gold",
                // fontSize: "23px",
                // position: "relative",
                // bottom: "12px",
              }}
              className="timeline-item"
            >
              <div className="circle" style={{ fontSize: "24px" }}>
                {playBtnClicked ? (
                  <div
                    style={{ position: "absolute", top: "-3px", left: "-2px" }}
                  >
                    <BsFillPauseFill />{" "}
                  </div>
                ) : (
                  <div
                    style={{ position: "absolute", top: "-3px", left: "-1px" }}
                  >
                    <BsFillPlayFill />
                  </div>
                )}
              </div>
              <div className="line"></div>
              <div className="date">Play</div>
            </div> */}
          </>
        )}
        {timestamps.map((timestamp, index) => (
          <div
            className="timeline-item"
            // onClick={() => {
            //   onSelectTimestamp(timestamp);
            // }}
            key={timestamp + index}
          >
            <div className="date">
              {timestamps.length - index - 1 == 0
                ? "-18h"
                : `-${(timestamps.length - index - 1) * 5}d`}
            </div>
            <div
              className={`circle ${isActive === index ? "active" : ""}`}
              onClick={() => {
                setIsActive(index);
                onSelectTimestamp(timestamp);
              }}
            ></div>
            <div className="line"></div>
            {/* {timestamps.length - index - 1 == 0 ? (
              <span
                className="material-symbols-outlined"
                style={{ color: "white", fontSize: "1em" }}
              >
                analytics
              </span>
            ) : (
              <span
                className="material-symbols-outlined"
                style={{ color: "white", fontSize: "1em" }}
              >
                image
              </span>
            )} */}
            <span
              className="material-symbols-outlined"
              style={{ color: "white", fontSize: "1em" }}
            >
              image
            </span>
          </div>
        ))}
        {showAlert && (
          <div
            className="timeline-item"
            onClick={() => {
              onSelectTimestamp("alert");
            }}
          >
            <div className="date">Now</div>
            <div
              className={`circle ${
                isActive === timestamps.length ? "active" : ""
              }`}
              onClick={() => {
                setIsActive(timestamps.length);
              }}
            ></div>
            <div className="line"></div>
            <span
              className="material-symbols-outlined"
              style={{ color: "white", fontSize: "1em" }}
            >
              analytics
            </span>
          </div>
        )}
      </div>
      <div className="btns">
        {showAlertButton && (
          <div
            className={`priority-tasking`}
            onClick={() => {
              if (!showPopUp) {
                setShowPopUp(true);
                setShowAlertButton(false);
              }
            }}
          >
            <span className="material-symbols-outlined">refresh</span>{" "}
            <div>Update</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RadioButtonList;
