const analyticsData = () => {
  const dataArr = [
    {
      city: "Cianjur",
      models: {
        boxTitle: "Built-up Area",
        title: "building_detection",
        // data: "6",
        timestamp_dict: [
          {
            "2022-11-11": {
              downOrUp: "up",
              active_fires: "1,751.43 ha",
              detected: "0 ha",
              detected_title: "Lost Building Area",
              time: "5 days ago",
              table_details: [
                {
                  time: "18 hours ago",
                  area: "1,751.43 ha",
                  stats: "0%",
                  downOrUp: "up",
                  downOrUpWord: "up",
                  active: "0 ha",
                  downOrUpBy: "0",
                },
                {
                  time: "5 days ago",
                  area: "1,751.43 ha",
                  stats: "0%",
                  downOrUp: "up",
                  downOrUpWord: "up",
                  active: "0 ha",
                  downOrUpBy: "0",
                },
              ],
            },
          },
          {
            "2022-11-16": {
              downOrUp: "up",
              active_fires: "1,751.43 ha",
              detected: "0 ha",
              detected_title: "Lost Building Area",
              time: "18 hours ago",
              table_details: [
                {
                  time: "18 hours ago",
                  area: "1,751.43 ha",
                  stats: "0%",
                  downOrUp: "up",
                  downOrUpWord: "up",
                  active: "0 ha",
                  downOrUpBy: "0",
                },
                {
                  time: "5 days ago",
                  area: "1,751.43 ha",
                  stats: "0%",
                  downOrUp: "up",
                  downOrUpWord: "up",
                  active: "0 ha",
                  downOrUpBy: "0",
                },
              ],
            },
          },
          {
            "2022-11-21": {
              downOrUp: "up",
              active_fires: "1,751.43 ha",
              detected: "74.18 ha",
              detected_title: "Lost Building Area",
              time: "Now",
              table_details: [
                {
                  time: "18 hours ago",
                  area: "1,751.43 ha",
                  stats: "0%",
                  downOrUp: "up",
                  downOrUpWord: "up",
                  active: "0 ha",
                  downOrUpBy: "0",
                },
                {
                  time: "5 days ago",
                  area: "1,751.43 ha",
                  stats: "0%",
                  downOrUp: "up",
                  downOrUpWord: "up",
                  active: "0 ha",
                  downOrUpBy: "0",
                },
              ],
            },
          },
        ],
      },
    },
    {
      city: "Los Angeles",
      models: {
        boxTitle: "Vessels Detected",
        title: "ship_detection",
        // data: "6",
        timestamp_dict: [
          {
            "2023-09-24": {
              downOrUp: "down",
              active_fires: 62,
              detected: "453",
              detected_title: "Unique MMSIs",
              time: "5 days ago",
              table_details: [
                {
                  time: "18 hours ago",
                  area: "58",
                  stats: "6.45%",
                  downOrUp: "down",
                  downOrUpWord: "up",
                  active: "479",
                  downOrUpBy: "26",
                },
                {
                  time: "5 days ago",
                  area: "62",
                  stats: "0%",
                  downOrUp: "up",
                  downOrUpWord: "up",
                  active: "453",
                  downOrUpBy: "0",
                },
              ],
            },
          },
          {
            "2023-10-05": {
              downOrUp: "down",
              active_fires: 58,
              detected: "479",
              detected_title: "Unique MMSIs",
              time: "18 hours ago",
              table_details:  [
                {
                  time: "18 hours ago",
                  area: "58",
                  stats: "6.45%",
                  downOrUp: "down",
                  downOrUpWord: "up",
                  active: "479",
                  downOrUpBy: "26",
                },
                {
                  time: "5 days ago",
                  area: "62",
                  stats: "0%",
                  downOrUp: "up",
                  downOrUpWord: "up",
                  active: "453",
                  downOrUpBy: "0",
                },
              ],
            },
          },
          {
            "2023-10-20": {
              downOrUp: "down",
              active_fires: 58,
              detected: "463",
              detected_title: "Unique MMSIs",
              time: "Now",
              table_details: [
                {
                  time: "18 hours ago",
                  area: "58",
                  stats: "6.45%",
                  downOrUp: "down",
                  downOrUpWord: "up",
                  active: "479",
                  downOrUpBy: "26",
                },
                {
                  time: "5 days ago",
                  area: "62",
                  stats: "0%",
                  downOrUp: "up",
                  downOrUpWord: "up",
                  active: "453",
                  downOrUpBy: "0",
                },
              ],
            },
          },
        ],
      },
    },
    {
      city: "Dilolo",
      models: {
        boxTitle: "Burn Scar Area",
        title: "fire_detection",
        // data: "22",
        timestamp_dict: [
          {
            "2024-07-26": {
              downOrUp: "up",
              active_fires: "5.32 km2",
              detected: 7,
              detected_title: "Active fires",
              time: "15 days ago",
              table_details: [
                {
                  time: "18 hours ago",
                  area: "51.19 km2",
                  stats: "263.12%",
                  downOrUp: "up",
                  downOrUpWord: "down",
                  active: "23",
                  downOrUpBy: "11",
                },
                {
                  time: "5 days ago",
                  area: "14.09 km2",
                  stats: "29.01%",
                  downOrUp: "up",
                  downOrUpWord: "up",
                  active: "34",
                  downOrUpBy: "33",
                },
                {
                  time: "10 days ago",
                  area: "10.92 km2",
                  stats: "105.14%",
                  downOrUp: "up",
                  downOrUpWord: "down",
                  active: "1",
                  downOrUpBy: "6",
                },
                {
                  time: "15 days ago",
                  area: " 5.32 km2",
                  stats: "0%",
                  downOrUp: "down",
                  downOrUpWord: "up",
                  active: "7",
                  downOrUpBy: "0",
                },
              ],
            },
          },
          {
            "2024-07-31": {
              downOrUp: "up",
              active_fires: "10.92 km2",
              detected: 1,
              detected_title: "Active fires",
              time: "10 days ago",
              table_details: [
                {
                  time: "18 hours ago",
                  area: "51.19 km2",
                  stats: "263.12%",
                  downOrUp: "up",
                  downOrUpWord: "down",
                  active: "23",
                  downOrUpBy: "11",
                },
                {
                  time: "5 days ago",
                  area: "14.09 km2",
                  stats: "29.01%",
                  downOrUp: "up",
                  downOrUpWord: "up",
                  active: "34",
                  downOrUpBy: "33",
                },
                {
                  time: "10 days ago",
                  area: "10.92 km2",
                  stats: "105.14%",
                  downOrUp: "up",
                  downOrUpWord: "down",
                  active: "1",
                  downOrUpBy: "6",
                },
                {
                  time: "15 days ago",
                  area: " 5.32 km2",
                  stats: "0%",
                  downOrUp: "down",
                  downOrUpWord: "up",
                  active: "7",
                  downOrUpBy: "0",
                },
              ],
            },
          },
          {
            "2024-08-05": {
              downOrUp: "up",
              active_fires: "14.09 km2",
              detected: 34,
              detected_title: "Active fires",
              time: "5 days ago",
              table_details: [
                {
                  time: "18 hours ago",
                  area: "51.19 km2",
                  stats: "263.12%",
                  downOrUp: "up",
                  downOrUpWord: "down",
                  active: "23",
                  downOrUpBy: "11",
                },
                {
                  time: "5 days ago",
                  area: "14.09 km2",
                  stats: "29.01%",
                  downOrUp: "up",
                  downOrUpWord: "up",
                  active: "34",
                  downOrUpBy: "33",
                },
                {
                  time: "10 days ago",
                  area: "10.92 km2",
                  stats: "105.14%",
                  downOrUp: "up",
                  downOrUpWord: "down",
                  active: "1",
                  downOrUpBy: "6",
                },
                {
                  time: "15 days ago",
                  area: " 5.32 km2",
                  stats: "0%",
                  downOrUp: "down",
                  downOrUpWord: "up",
                  active: "7",
                  downOrUpBy: "0",
                },
              ],
            },
          },
          {
            "2024-08-10": {
              downOrUp: "up",
              downOrUpWord: "up",
              active_fires: "51.19 km2",
              detected: 23,
              detected_title: "Active fires",
              time: "18 hours ago",
              table_details:[
                {
                  time: "18 hours ago",
                  area: "51.19 km2",
                  stats: "263.12%",
                  downOrUp: "up",
                  downOrUpWord: "down",
                  active: "23",
                  downOrUpBy: "11",
                },
                {
                  time: "5 days ago",
                  area: "14.09 km2",
                  stats: "29.01%",
                  downOrUp: "up",
                  downOrUpWord: "up",
                  active: "34",
                  downOrUpBy: "33",
                },
                {
                  time: "10 days ago",
                  area: "10.92 km2",
                  stats: "105.14%",
                  downOrUp: "up",
                  downOrUpWord: "down",
                  active: "1",
                  downOrUpBy: "6",
                },
                {
                  time: "15 days ago",
                  area: " 5.32 km2",
                  stats: "0%",
                  downOrUp: "down",
                  downOrUpWord: "up",
                  active: "7",
                  downOrUpBy: "0",
                },
              ],
            },
          },
          {
            "2024-08-15": {
              downOrUp: "up",
              downOrUpWord: "up",
              active_fires: "97.56 km2",
              detected: 13,
              detected_title: "Active fires",
              time: "Now",
              table_details: [
                {
                  time: "18 hours ago",
                  area: "51.19 km2",
                  stats: "263.12%",
                  downOrUp: "up",
                  downOrUpWord: "down",
                  active: "23",
                  downOrUpBy: "11",
                },
                {
                  time: "5 days ago",
                  area: "14.09 km2",
                  stats: "29.01%",
                  downOrUp: "up",
                  downOrUpWord: "up",
                  active: "34",
                  downOrUpBy: "33",
                },
                {
                  time: "10 days ago",
                  area: "10.92 km2",
                  stats: "105.14%",
                  downOrUp: "up",
                  downOrUpWord: "down",
                  active: "1",
                  downOrUpBy: "6",
                },
                {
                  time: "15 days ago",
                  area: " 5.32 km2",
                  stats: "0%",
                  downOrUp: "down",
                  downOrUpWord: "up",
                  active: "7",
                  downOrUpBy: "0",
                },
              ],
            },
          },
        ],
      },
    },
    {
      city: "Mahebourg",
      models: {
        boxTitle: "Total Slick Area",
        title: "oil_spills",
        data: "",
        timestamp_dict: [
          {
            "2020-08-16": {
              downOrUp: "up",
              active_fires: "17.34 km2",
              detected: 24,
              detected_title: "Number of Slicks",
              time: "Now",
              table_details: [
                {
                  time: "18 hours ago",
                  area: "21.16 km2",
                  stats: "100%",
                  downOrUp: "up",
                  downOrUpWord: "up",
                  active: "6",
                  downOrUpBy: "6",
                },
                {
                  time: "5 days ago",
                  area: "0 km2",
                  stats: "",
                  downOrUp: "",
                  downOrUpWord: "up",
                  active: "0",
                  downOrUpBy: "0",
                },
              ],
            },
          },
          {
            "2020-08-10": {
              downOrUp: "up",
              active_fires: "21.16 km2",
              detected: 6,
              detected_title: "Number of Slicks",
              time: "18 hours ago",
              table_details: [
                {
                  time: "18 hours ago",
                  area: "21.16 km2",
                  stats: "100%",
                  downOrUp: "up",
                  downOrUpWord: "up",
                  active: "6",
                  downOrUpBy: "6",
                },
                {
                  time: "5 days ago",
                  area: "0 km2",
                  stats: "",
                  downOrUp: "",
                  downOrUpWord: "up",
                  active: "0",
                  downOrUpBy: "0",
                },
              ],
            },
          },
          {
            "2020-07-29": {
              downOrUp: "up",
              active_fires: 0,
              detected: "0",
              detected_title: "Number of Slicks",
              time: "5 days ago",
              table_details: [
                {
                  time: "18 hours ago",
                  area: "21.16 km2",
                  stats: "100%",
                  downOrUp: "up",
                  downOrUpWord: "up",
                  active: "6",
                  downOrUpBy: "6",
                },
                {
                  time: "5 days ago",
                  area: "0 km2",
                  stats: "",
                  downOrUp: "",
                  downOrUpWord: "up",
                  active: "0",
                  downOrUpBy: "0",
                },
              ],
            },
          },
        ],
      },
    },
  ];
  return dataArr;
};

export default analyticsData;
